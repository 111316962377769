import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {CompanySharedService} from "../../_services/company-shared.service";

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentJob: any;
  localCurrentJob: any;
  showUpper = true;
  currentView: any = {assetJobStatus: [], assetInventoryStatus: {}, assetSnmpJobStatus: []};
  assetSNMPJobCols: any = ['assetName', 'assetIp', 'reason', 'status', 'vulnerability_count', 'discoveredProtocol',
    'risk_score', 'noauth_vulnerability_count', 'base_score'];
  assetJobStatusCols: any = ['assetName', 'assetIp', 'reason', 'status', 'pingStatus', 'topPortsScan',
    'vulnerability_count', 'risk_score', 'noauth_vulnerability_count'];
  assetJobStatusCustomCols: any = [
    {
      col: 'status', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },{
      col: 'pingStatus', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },
    {
      col: 'topPortsScan', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },
    {col: 'reason', filter: 'ellipsis:25'}, {col: 'vulnerability_count', isHtml: true}];
  assetSNMPJobCustomCols: any = [
    {
      col: 'status', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    }, {col: 'reason', filter: 'ellipsis:25'}, {col: 'vulnerability_count', isHtml: true}];

  constructor(private cs: CompanySharedService) {
    cs.jobsUpdateEVE.subscribe((value: any) => {
      this.initJobView(value);
    });
  }

  Objectkeys = Object.keys;

  ngOnInit(): void {
    this.initJobView(this.currentJob);
  }

  initJobView(cJob: any): any {
    this.currentView = {assetJobStatus: [], assetInventoryStatus: {}, assetSnmpJobStatus: []};
    const currentView = {assetJobStatus: [], assetInventoryStatus: {}, assetSnmpJobStatus: []};
    if (!cJob.job_data.job_message.assetJobStatus) {
      cJob.job_data.job_message.assetJobStatus = [];
    } else {
      cJob.job_data.job_message.assetJobStatus.forEach((obj: any) => {
        obj.assetName = (obj.assetRef) ? obj.assetRef.name : '-';
        obj.assetIp =  (obj.ipaddress) ? obj.ipaddress : '-';
        obj.noauth_vulnerability_count = obj.noauth_vulscount;
        obj.vulnerability_count = (!obj.vulcount)
          ? `<span class="fw5">${obj.vulcount}</span>`
          : `<span class="fw5 tprime">${obj.vulcount}</span>`;
      });
    }
    if (!cJob.job_data.job_message.assetInventoryStatus) {
      cJob.job_data.job_message.assetInventoryStatus = {};
    }
    if (!cJob.job_data.job_message.assetSnmpJobStatus) {
      cJob.job_data.job_message.assetSnmpJobStatus = [];
    } else {
      cJob.job_data.job_message.assetSnmpJobStatus.forEach((obj: any) => {
        obj.assetName = (obj.assetRef) ? obj.assetRef.name : '-';
        obj.assetIp =  (obj.assetRef) ? obj.assetRef.ip : '-';
        obj.noauth_vulnerability_count = obj.noauth_vulscount;
        obj.vulnerability_count = (!obj.vulcount)
          ? `<span class="fw5">${obj.vulcount}</span>`
          : `<span class="fw5 tprime">${obj.vulcount}</span>`;
      });
    }
    currentView.assetJobStatus = cJob.job_data.job_message.assetJobStatus;
    currentView.assetInventoryStatus = cJob.job_data.job_message.assetInventoryStatus;
    currentView.assetSnmpJobStatus = cJob.job_data.job_message.assetSnmpJobStatus;
    this.currentView = Object.assign({}, currentView);
  }

  ngOnChanges(changes: SimpleChanges): any {
    const currentJob: SimpleChange = changes.currentJob;
    this.localCurrentJob = currentJob.currentValue;
    if (!currentJob.firstChange) {
      this.initJobView(this.localCurrentJob);
    }
  }

  ngOnDestroy(): void {
    this.currentJob = undefined;
  }
}
