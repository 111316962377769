<div class="remedia-container">
  <mat-sidenav-container class="remedia-sidenav-container h-100vh" (backdropClick)="close('backdrop')">
    <mat-sidenav-content>
      <app-s-crud-t (addCallback)="addEditAppBaseline()" (actionCallback)="actionCalback($event)"
              *ngIf="appBaselineColOptions && appBaselineColOptions.columns &&
               aS.hasPermission('applicationbaseline', 'read')"
              [tOption]="appBaselineColOptions"></app-s-crud-t>
    </mat-sidenav-content>
    <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-50">
      <mat-card class="w-100">
        <button class="position-absolute r-1p mt--10"  aria-label="close modal icon" matTooltip="Close"
                (click)="close();" mat-icon-button=""> <mat-icon>close</mat-icon> </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw3">
            <span *ngIf="!currentAppBaseline._id">Add</span>
            <span *ngIf="currentAppBaseline._id">Edit</span> Application Baseline Rules
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="currentAppBaseline">
          <form #appBaseForm="ngForm" role="form">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Rule Name</mat-label>
              <input type="text" matInput placeholder="Rule Title" name="name" [(ngModel)]="currentAppBaseline.name" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select OS Type</mat-label>
              <mat-select name="os_type" required [(ngModel)]="currentAppBaseline.os_type">
                <mat-option value="windows">Windows</mat-option>
                <mat-option value="linux">Linux</mat-option>
                <mat-option value="mac">Mac</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Enter OS Name</mat-label>
              <input type="text" matInput placeholder="OS Name" name="osname" [(ngModel)]="currentAppBaseline.osname" required>
              <mat-hint>Windows Server 2012 R2</mat-hint>
            </mat-form-field>
            <ng-container *ngIf="currentAppBaseline.deniedApplications">
              <h3 class="fs1 fw2 mb-0">Add Denied Application(s)</h3>
              <mat-form-field class="w-100 mat-small" appearance="outline">
                <mat-label>Denied Application</mat-label>
                <input matInput type="text" name="denied" placeholder="Enter application full name"
                       [matAutocomplete]="deniedApp" [formControl]="deniedAppCtrl"
                       [(ngModel)]="denied" matTooltip="Enter denied application name and click 'Plus' icon to add">
                <button mat-button *ngIf="denied" matSuffix mat-icon-button aria-label="Add Denied"
                        (click)="currentAppBaseline.deniedApplications.push(denied); denied = '';">
                  <mat-icon class="tprime">add</mat-icon>
                </button>
                <mat-autocomplete #deniedApp="matAutocomplete">
                  <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let app of deniedApplications" [value]="app">
                      <span class="mat-small" [matTooltip]="app">{{app}}</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <ul class="w-100 bor-1 p-0" *ngIf="currentAppBaseline.deniedApplications &&
               currentAppBaseline.deniedApplications.length">
                <li class="d-flex flex-row aline-items-center bb-1 px-2 py-1"
                    *ngFor="let deniedApp of currentAppBaseline.deniedApplications; index as i; trackBy:loaderService.trackByFn">
                  <span class="tprime">{{deniedApp}}</span>
                  <span class="spacer">&nbsp;</span>
                  <span class="pointer"><mat-icon (click)="currentAppBaseline.deniedApplications.splice(i, 1)"
                                                            class="mat-18">delete</mat-icon></span>
                </li>
              </ul>
              <p class="fs1 fw3" *ngIf="!currentAppBaseline.deniedApplications ||
               !currentAppBaseline.deniedApplications.length">No denied application(s) added!</p>
            </ng-container>
            <hr>
            <ng-container *ngIf="currentAppBaseline.mandatoryApplications">
              <h3 class="fs1 fw2">Add Mandatory Application(s)</h3>
              <mat-form-field class="w-100 mat-small" appearance="outline">
                <mat-label>Mandatory Application</mat-label>
                <input matInput type="text" name="denied" placeholder="Enter application full name"
                       [matAutocomplete]="mandatoryApp" [formControl]="mandatoryAppCtrl"
                       [(ngModel)]="mandatory" matTooltip="Enter mandatory application name and press enter">
                <button mat-button *ngIf="mandatory" matSuffix mat-icon-button aria-label="Add Denied"
                        (click)="currentAppBaseline.mandatoryApplications.push(mandatory); mandatory = '';">
                  <mat-icon class="tprime">add</mat-icon>
                </button>
                <mat-autocomplete #mandatoryApp="matAutocomplete">
                  <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let app of mandatoryApplications" [value]="app">
                      <span class="mat-small" [matTooltip]="app">{{app}}</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <ul class="w-100 bor-1 p-0" *ngIf="currentAppBaseline.mandatoryApplications &&
               currentAppBaseline.mandatoryApplications.length">
                <li class="d-flex flex-row aline-items-center bb-1 px-2 py-1"
                    *ngFor="let mandatoryApp of currentAppBaseline.mandatoryApplications; index as i; trackBy:loaderService.trackByFn">
                  <span class="tprime">{{mandatoryApp}}</span>
                  <span class="spacer">&nbsp;</span>
                  <span class="pointer"><mat-icon (click)="currentAppBaseline.mandatoryApplications.splice(i, 1)"
                                                            class="mat-18">delete</mat-icon></span>
                </li>
              </ul>
              <p class="fs1 fw3" *ngIf="!currentAppBaseline.mandatoryApplications ||
               !currentAppBaseline.mandatoryApplications.length">No mandatory application(s) added!</p>
            </ng-container>
            <hr>
            <mat-checkbox name="isLightWeightMandatory" class="mb-2 mt-1" [(ngModel)]="currentAppBaseline.isLightWeightMandatory"
                          value="" color="primary">Lightweight Agent -Mandatory</mat-checkbox>
            <br>
            <button type="button" color="primary" class="mr-2"
                    [disabled]="!appBaseForm.form.valid || !currentAppBaseline || ( !currentAppBaseline.mandatoryApplications.length
                     && !currentAppBaseline.deniedApplications.length )" (click)="saveAppBaseline()" mat-raised-button="">
              Save</button>
            <button (click)="close()" mat-stroked-button="">Cancel</button>
          </form>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
