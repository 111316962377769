<div  *ngIf="!currentIntegration" [@zoomInOnEnter] [ngClass]="{'integration-height': onboarding}">
  <div class="d-flex align-items-center">
    <div class="mat-title fw2">Integrations</div>
    <div class="spacer"></div>
    <mat-form-field class="search-tool fs1" floatLabel="never">
      <input matInput name="integsearch" [(ngModel)]="integrationSearch" placeholder="Filter"
             autocomplete="off">
      <button mat-button *ngIf="!integrationSearch || integrationSearch === ''" aria-label="Search" matSuffix mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
      <button mat-button *ngIf="integrationSearch" matSuffix mat-icon-button aria-label="Clear"
              (click)="integrationSearch='';">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-lg-2 mb-4" [ngClass]="{'col-lg-2': !onboarding, 'col-lg-3': onboarding}"
         *ngFor="let product of integrations | search: integrationSearch">
      <mat-card (click)="setIntegration(product)"
                class=" text-center pull-up pointer mat-elevation-z4 flex-column align-items-center justify-content-center">
        <img class="provider-img"
             src="/assets/images/providers/provider-{{ product.img }}.png">
        <div class=" my-3 fs1-2 fw3">{{product.title}}</div>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="currentIntegration" [@zoomInOnEnter]>
  <mat-card>
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <img class="selected-img"
             src="/assets/images/providers/provider-{{ currentIntegration.img }}.png">
        <span class="fw2">{{currentIntegration.title}}</span>
        <span class="spacer"></span>
        <button class="position-absolute r-1p" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="currentIntegration = undefined;"><mat-icon>close</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="pt-2">
      <mat-tab-group (selectedIndexChange)="updateSelectionIndex($event)">
        <mat-tab label="Credentials">
          <ng-template mat-tab-label>
            <mat-icon class="mat-24 mr-2">vpn_key</mat-icon> Credentials
          </ng-template>
          <ng-template matTabContent>
            <span>&nbsp;</span>
            <div class="d-flex w-25 ml-2 mb-2 mt-3 align-items-center">
              <span class="fs1 fw2 text-uppercase mr-3">Credentials</span>
              <span class="spacer"></span>
              <span class="pr-3">
                <button [disabled]="newCred" (click)="addCred()" color="primary" mat-icon-button="">
                  <mat-icon class="mat-24">add_circle_outline</mat-icon>
                </button>
              </span>
            </div>
            <div class="d-flex align-items-center w-100" *ngIf="newCred">
              <app-dynamic-form class="col-lg-8" (cancelCallBack)="cancel()" (saveCallBack)="save($event)"
                                *ngIf="credData && formElements && formElements.length"
                                [listOfFormElements]="formElements" [Valuesoutput]="credData"></app-dynamic-form>
            </div>
            <div class="d-flex align-items-top w-100" *ngIf="!newCred">
              <div class="w-25 px-2">
                <mat-list dense class="fnav-list bor-1 br-5 w-100">
                  <mat-list-item class="bb-1" [ngClass]="{'active': cred._id === this.credData._id}"
                                 *ngFor="let cred of integrationsData; let i = index;">
                    <span class="pointer" [matTooltip]="cred.name"
                          (click)="setCred(cred)">{{cred.name | camelToHuman: true | ellipsis:28}}</span>
                    <span class="spacer"></span>
                    <span class="pointer px-1" (click)="delCred(cred)"><mat-icon class="mat-24">delete</mat-icon></span>
                  </mat-list-item>
                </mat-list>
              </div>
              <div class="w-75 px-4 mt--20">
                <app-dynamic-form class="col-lg-8" (cancelCallBack)="cancel()" (saveCallBack)="save($event)"
                                  *ngIf="credData && formElements && formElements.length"
                                  [listOfFormElements]="formElements" [Valuesoutput]="credData"></app-dynamic-form>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab [disabled]="!integrationsData || !integrationsData.length"
                 *ngIf="currentIntegration.companyMappingRequired && currentIntegration.integrationtype === 'psa'"
                 label="Company Mapping">
          <ng-template matTabLabel>
            <mat-icon class="mat-24 mr-2">domain</mat-icon>
            Company Mapping
          </ng-template>
          <ng-template matTabContent>
            <span>&nbsp;</span>
            <app-company-mapping [integrationsData]="integrationsData" [onboarding]="onboarding"
                                 (updateCompanyList)="triggerOnboarding()"
                                 [currentIntegration]="currentIntegration"></app-company-mapping>
          </ng-template>
        </mat-tab>
        <mat-tab [disabled]="!integrationsData || !integrationsData.length"
                 *ngIf="currentIntegration.companyMappingRequired && currentIntegration.integrationtype !== 'psa'"
                 label="Company Mapping">
          <ng-template matTabLabel>
            <mat-icon class="mat-24 mr-2">domain</mat-icon>
            Company Mapping
          </ng-template>
          <ng-template matTabContent>
            <span>&nbsp;</span>
            <app-nonpsa-company-mapping [integrationsData]="integrationsData" [onboarding]="onboarding"
                                 (updateCompanyList)="triggerOnboarding()"
                                 [currentIntegration]="currentIntegration"></app-nonpsa-company-mapping>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
