<app-s-table aria-label="NotificationRules" role="table" [sTableOptions]="notiTableOptions" *ngIf="!addEditRules"
             (filterCallback)="rulefilterCall($event)" (actionCallback)="ruleactionCall($event)"
             (refreshCallback)="getNotificationRulesData()"
             (pageCallback)="rulepageCall($event)" (addCallback)="ruleaddTableData()"
             (timerCallback)="getNotificationRulesData()"></app-s-table>
<ng-container *ngIf="addEditRules">
  <mat-card>
    <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
            (click)="addEditRules = !addEditRules; getNotificationRulesData();" mat-icon-button="">
      <mat-icon>close</mat-icon>
    </button>
    <mat-card-header>
      <mat-card-title>Manage Alert Notification Rules</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion class="rules-headers-align">
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Enable Alerts </mat-panel-title>
            <mat-panel-description>
              Choose the alerts that you would like to get notified on.
              <mat-icon>alert</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field floatPlaceholder="auto" class="mb-2 w-30p" appearance="outline">
            <mat-label>Rule Name</mat-label>
            <input matInput [(ngModel)]="ruleName" name="ruleName" type="text" required placeholder="Enter rule name">
          </mat-form-field>
          <br>
          <button (click)="selectDeselectAll(true)" class="mr-2 mb-2" mat-stroked-button="">Select All</button>
          <button (click)="selectDeselectAll(false)" class="mr-2 mb-2" mat-stroked-button="">Deselect All</button>
          <mat-list class="w-100 bor-1">
            <ng-container *ngFor="let item of notificationRule">
              <ng-container *ngFor="let it of item.alerts; let i = index">
                <mat-list-item class="bb-1" *ngFor="let event of it.events">
                  <span class="mr-2">
                    <mat-checkbox [(ngModel)]="event.selected" color="primary" [name]="event.id"></mat-checkbox>
                  </span>
                  <span class="mb-2 ml-2" [matTooltip]="it.name">{{event.name}}</span>
                </mat-list-item>
              </ng-container>
            </ng-container>
          </mat-list>
          <p class="fw3 pt-1 mat-small">Please select either 'Remediation By Company' or 'Remediation By Asset'</p>
          <mat-action-row>
            <button mat-raised-button color="primary" [disabled]="!ruleName" (click)="validationRemediateCheck()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Integrations
            </mat-panel-title>
            <mat-panel-description>
              Choose the integrations
              <mat-icon>map</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list dense class="w-100 bor-1">
            <ng-container *ngFor="let product of mappedIntegrations">
              <mat-list-item *ngIf="iHash[product.integrationName]">
                <span class="mx-2">
                  <mat-checkbox [(ngModel)]="product.selected" color="primary" [name]="product.id"></mat-checkbox>
                </span>
                <span class=" mx-2">
                  <img *ngIf="product && product.integrationName"
                       class="mat-24" src="/assets/images/providers/provider-{{ iHash[product.integrationName].img }}.png">
                </span>
                <span class="mx-2 fs1-2 fw3">{{iHash[product.integrationName].name}}</span>
              </mat-list-item>
            </ng-container>
          </mat-list>
          <mat-action-row>
            <button mat-raised-button class="mr-2" (click)="prevStep()">Previous</button>
            <button *ngIf="mode === 'global'" mat-raised-button color="primary" appPreventMultiClick  (click)="getSelectedCreds()">Next</button>
            <button  *ngIf="mode !== 'global'" mat-raised-button color="primary" appPreventMultiClick (click)="getActionParams()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Action Parameters
            </mat-panel-title>
            <mat-panel-description>
              Fill the action parameters and 'Save' the notification rule.
              <mat-icon>date_range</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
            <mat-tab-group [selectedIndex]="selectedIndex" *ngIf="selectedIntegrations && selectedIntegrations.length"
                           (selectedIndexChange)="processFields(selectedIntegrations[$event].actionResult, $event)">
              <mat-tab *ngFor="let tab of selectedIntegrations; let index = index"
                       [label]="iHash[tab['integrationName']].name">
                <mat-form-field floatPlaceholder="auto" class="mt-4" appearance="outline" *ngIf="mode === 'global'">
                  <mat-label>Choose Credential</mat-label>
                  <mat-select name="cred{{selectedIndex}}" [(ngModel)]="selectedIntegrations[selectedIndex].credentialId"
                              placeholder="Choose Credential"
                              (ngModelChange)="processFields(selectedIntegrations[selectedIndex].actionResult, selectedIndex)">
                    <mat-option [value]="cred._id"
                                *ngFor="let cred of selectedIntegrationsCredsHash[tab.integrationName]">{{cred.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <app-dynamic-form class="col-lg-8" [isSaveBtn]="false" [isCloseBtn]="false"
                *ngIf="selectedIntegrations[this.selectedIndex].actionData &&
                 selectedIntegrations[this.selectedIndex].formElements &&
                  selectedIntegrations[this.selectedIndex].formElements.length"
                (valueUpdate)="actionChange($event)"
                [listOfFormElements]="selectedIntegrations[this.selectedIndex].formElements"
                                  [Valuesoutput]="selectedIntegrations[this.selectedIndex].actionData">
              </app-dynamic-form>
                <p class="bor-1 p-3" *ngIf="!selectedIntegrations[this.selectedIndex].actionData ||
                 !selectedIntegrations[this.selectedIndex].formElements ||
                  !selectedIntegrations[this.selectedIndex].formElements.length">
                  No parameters required for this integration. Please proceed to 'Save'.
                </p>
              </mat-tab>
            </mat-tab-group>
          <mat-action-row>
            <button mat-raised-button class="mr-2"  (click)="prevStep()">Previous</button>
            <button mat-raised-button color="primary" [disabled]="!ruleName || ruleName === ''" (click)="saveNotificationRule()">Save</button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
</ng-container>
