import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IntegrationsService} from '../../../api/services/integrations.service';
import {MyToastrService} from '../../../_services/toastr.service';
import {ConfirmDialogService} from '../../../_services/confirmdialog.service';
import {LoaderService} from '../../../_services/loader.service';
import {IntegrationMappingService} from '../../../api/services/integration-mapping.service';
import {CompanyService} from '../../../api/services/company.service';
import {BaseRequestService} from '../../../_services/base.service';
import {MatSelect} from '@angular/material/select';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {MatMenuTrigger} from '@angular/material/menu';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
  selector: 'app-nonpsa-company-mapping',
  templateUrl: './nonpsa-company-mapping.component.html',
  styleUrls: ['./nonpsa-company-mapping.component.scss']
})
export class NonpsaCompanyMappingComponent implements OnInit, OnChanges {

  @ViewChild('companySelect', {static: true}) companySelect!: MatSelect;
  @ViewChild('menuRefMenuTrigger', {static: false}) menuRefMenuTrigger: MatMenuTrigger;
  @Input() currentIntegration: any;
  @Input() integrationsData: any;
  @Input() onboarding: any;
  @Output() updateCompanyList = new EventEmitter();
  disableCreate = false;
  currentCredential: any;
  companyActionParams: any;
  companyParams: any = {};
  companyFormElements: any = [];
  companyList: any = [];
  /*Integration Mapping table*/
  cmpView = 'Type';
  cmpMapping: any = {selectedCompanies: [], mapCompanyList: []};
  companyMappingList: any = [];
  failedMappingList: any = [];
  addEditIntegration = false;
  inteTableOptions: any;
  integmappingcurrentPage = 0;
  integmappingfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  Objectkeys = Object.keys;

  forceSet = false;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  selectedCompany: any;
  selectedCompanyList: any = [];
  company: any; companies: any; allComp: any; companyHash: any = {}; sourceCompany: any;
  searchTxt: any = 'Search Company';
  protected onDestroySearch = new Subject<void>();
  /*Integration Mapping table*/
  constructor(private integrationsService: IntegrationsService, private toast: MyToastrService,
              private companyService: CompanyService, private baseService: BaseRequestService,
              private aS: AuthenticationService,
              private confirmDialog: ConfirmDialogService, private integrationMappingService: IntegrationMappingService,
              public loaderService: LoaderService) {
    this.inteTableOptions = {
      columns: [{
        header: 'Existing Company Name',
        columnDef: 'sourceCompanyName',
        filter: '',
        cell: '(element: any) => `${element.sourceCompanyName}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: `Company Name`,
        columnDef: 'destCompanyName',
        filter: '',
        cell: '(element: any) => `${element.destCompanyName}`',
        order: 3,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'Mapped Date',
        columnDef: 'c',
        filter: 'utcToLocale',
        cell: '(element: any) => `${element.c}`',
        order: 4,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }],
      sortOptions: {active: 'u', direction: 'asc'},
      faClass: 'Company',
      _pageData: [],
      tableOptions: {
        title: 'Mapping Info',
        id: 'inteTableOptions',
        isServerSide: true,
        selectText: 'integrationMapping',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          /*{
          text: 'Edit',
          icon: 'edit',
          callback: 'editFn',
          isGlobal: false
        },*/
          {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false}],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: (this.aS.hasPermission('integrations', 'companymapping')),
        columnSearch: true,
        compareData: false
      }
    };
  }

  ngOnInit(): void {
    this.currentCredential = this.integrationsData[0]._id;
    this.getCompanyActionParams();
    this.inteTableOptions.pageData = [];
    this.getIntegrationMapping(true);
    this.companyFilterCtrl.valueChanges.pipe(debounceTime(300),
      takeUntil(this.onDestroySearch)).subscribe(() => {
      this.filterCompanies();
    });
    this.getCompanies();
  }

  private filterCompanies(): void {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  getCompanies(search?: string): void {
    let cq: any;
    cq = {query: {bool: {must: [{exists: {field: 'description'}}], must_not: [{exists: {field: 'companyRef'}}]}}};
    if (search && search !== '') {
      cq.query.bool.must.push({ regexp: { name: { value: '.*' + search.toLowerCase() + '.*' } } });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({q, skip, limit, sort}).subscribe((result: any) => {
      if (result.data.length) {
        for (const c of result.data) { if (c._id) { this.companyHash[c._id] = c; } }
        result.data.sort((a: any, b: any) => {
          const c = (a.name) ? a.name.toLowerCase() : ''; const d = (b.name) ? b.name.toLowerCase() : '';
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
        this.companies = result.data; if (!search) { this.allComp = result.data; }
        this.filteredCompanies.next(result.data.slice());
        if (!this.companyCtrl.value) {
          this.companyCtrl.setValue(this.companies[0]._id);
          this.updateCurrentCompany(this.companies[0]._id);
        }
        if (this.forceSet) {
          setTimeout(() => {
            this.companyCtrl.setValue(this.companies[0]._id);
            this.updateCurrentCompany(this.companies[0]._id);
            this.forceSet = false;
          }, 1000);
        }
        this.searching = false;
        this.searchTxt = 'Search Company';
      } else {
        this.filteredCompanies.next([]);
        this.searching = false;
      }
    }, error => {
        // no errors in our simulated example
        this.searching = false;
        // handle error...
      });
  }

  closeCurrentCompany(event: any): void {
    if (!event && !this.selectedCompany) {
      this.getCompanies();
    }
  }

  updateCurrentCompany(event: any): void {
    this.sourceCompany = this.companyHash[event];
    this.selectedCompany = this.companyHash[event].name;
  }

  updateSelected($event: any): void {
    $event.forEach((obj: any, index: number) => {
      if ( !(this.cmpMapping.selectedCompanies.filter((x: any) => x._id === obj._id)).length) {
        this.cmpMapping.selectedCompanies.push($event[index]);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCompanyActionParams();
  }

  addToMapList(): void {
    const srcCmp = this.cmpMapping.mapCompanyList.filter((x: any) => x.source.name === this.sourceCompany.name);
    if (srcCmp.length) {
      this.toast.sToast('error', `${srcCmp[0].source.name} company already added`);
      return;
    }
    const destCmp = this.cmpMapping.mapCompanyList.filter((x: any) => x.destination.name === this.cmpMapping.destinationCompany.name);
    if (destCmp.length) {
      this.toast.sToast('error', `${destCmp[0].destination.name} company already added`);
      return;
    }
    this.cmpMapping.mapCompanyList.push({
      source: Object.assign({}, this.sourceCompany),
      destination: Object.assign({}, this.cmpMapping.destinationCompany)
    });
  }

  getCompanyActionParams(): void {
    this.loaderService.display(true, 'Getting action params...');
    this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.currentIntegration.name}&actionName=getCompanies`, 'get')
      .subscribe((result: any) => {
      this.loaderService.display(false);
      this.companyActionParams = result;
      this.companyFormElements = [];
      const tmpCFE: { label: any; key: any; required: any; type: any; }[] = [];
      if (result.parameters) {
        result.parameters.forEach((obj: any) => {
          tmpCFE.push({label: obj.description, key: obj.name, required: obj.required, type: obj.schema.type});
          this.companyParams[obj.name] = (obj.required) ? '' : obj.example;
        });
      }
      this.companyFormElements = tmpCFE.slice();
    });
  }

  getIntegrationCompanies(): void {
    this.loaderService.display(true, 'Getting companies...');
    const params: any = {
      integrationName: this.currentIntegration.name, integrationId: this.currentCredential,
      params: {
        action: {
          destination: this.companyActionParams.destination,
          verb: this.companyActionParams.verb, name: this.companyActionParams.name
        },
        params: {requestparams: this.companyParams}
      }
    };
    this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (!result.data.length) {
          this.toast.sToast('info', 'No companies found! Please change the search key and try.');
          return;
        }
        const sortKey = this.companyActionParams.DisplayFields[0];
        this.selectedCompanyList = Object.assign([], this.cmpMapping.selectedCompanies);
        this.companyList = result.data.sort((a: any, b: any) => {
          const c = a[sortKey].toLowerCase();
          const d = b[sortKey].toLowerCase();
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
      });
  }

  /*Integration Mapping Table*/
  integmappingshowHideLoading(status: any): void {
    const data = Object.assign({}, this.inteTableOptions);
    this.inteTableOptions = {};
    this.inteTableOptions = data;
    this.inteTableOptions.tableOptions.loading = status;
  }

  intesortCall(idata: any): void {
    this.inteTableOptions.sortOptions = idata;
    this.getIntegrationMapping();
  }

  integmappingeditFn(idata: any): void {
    console.log(idata);
    this.integmappingshowHideLoading(true);
    setTimeout(() => {
      this.integmappingshowHideLoading(false);
    }, 1000);
  }

  integmappingdeleteFn(idata: any): void {
    const titleName = 'Delete Mapping';
    const message = 'Are you sure you want to delete the mapping of ' + idata.sourceCompanyName + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/integrations/companyMapping/${idata._id}`, 'delete').subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Mapping deleted successfully');
            setTimeout(() => {
              this.getIntegrationMapping();
            }, 3000);
          } else {
            this.toast.sToast('error', 'Error deleting this mapping. Contact support!');
          }
        });
      }
    });
  }

  integmappingactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.integmappingeditFn(dataRow);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.integmappingdeleteFn(dataRow);
    }
  }

  integmappingfilterCall(idata: any): void {
    const fields: any = [];
    this.inteTableOptions.columns.forEach((obj: any) => {
      fields.push(obj.columnDef);
    });
    this.integmappingfilterQuery = (idata && idata.length > 0) ? {
      multi_match: {
        query: idata,
        type: 'phrase_prefix',
        fields
      }
    } : undefined;
    this.getIntegrationMapping();
  }

  integmappingpageCall(event: any): void {
    this.inteTableOptions.tableOptions.pageSize = event.pageSize;
    this.integmappingcurrentPage = event.pageIndex;
    this.getIntegrationMapping();
  }

  integmappingaddTableData(): void {
    this.addEditIntegration = true;
    this.cmpMapping = {selectedCompanies: [], mapCompanyList: []};
    this.cmpView = 'Type';
  }

  integmappingtimerCallData(): void {
    this.getIntegrationMapping();
  }

  getIntegrationMapping(isInit?: boolean): void {
    this.integmappingshowHideLoading(true);
    let params: any;
    params = {query: {bool: {must: []}}};
    if (this.integmappingfilterQuery && this.integmappingfilterQuery.multi_match) {
      params.query.bool.must.push(this.integmappingfilterQuery);
    }
    this.inteTableOptions.serverSide = {
      service: 'baseService', fn: 'doRequest', q: {
        query: {
          bool: {
            must: [
              {match: {'integrationName.keyword': this.currentIntegration.name + ''}},
            {match: {'credentialId.keyword': this.currentCredential + ''}}]
          }
        }
      }
    };
    const query = {
      query: {
        bool: {
          must: [
            {match: {'integrationName.keyword': this.currentIntegration.name + ''}},
            {match: {'credentialId.keyword': this.currentCredential + ''}}]
        }
      }
    };

    const q = JSON.stringify(query);
    const skip = this.integmappingcurrentPage;
    const limit = this.inteTableOptions.tableOptions.pageSize;
    let sort: any = [{}];
    if (this.inteTableOptions.sortOptions && this.inteTableOptions.sortOptions.direction && this.inteTableOptions.sortOptions.direction !== '') {
    const orderArr = ['sourceCompanyName','_id'];
    if (orderArr.indexOf(this.inteTableOptions.sortOptions.active) > -1) {
      sort[0][this.inteTableOptions.sortOptions.active + '.keyword'] = { order: this.inteTableOptions.sortOptions.direction };
    } else {
      sort[0][this.inteTableOptions.sortOptions.active] = { order: this.inteTableOptions.sortOptions.direction };
    }
  }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['c', 'u', '_id', '_type_', 'credentialName','credentialId', 'destCompanyId', 'destCompanyName', 'integrationName', 'sourceCompanyId', 'sourceCompanyName']);
    this.baseService.doRequest(`/api/integrations/companyMapping`,
      'get', null, {q, skip, limit,sort, fields}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.inteTableOptions.pageData = result.data;
      this.inteTableOptions.tableOptions.pageTotal = result.total;
      this.integmappingshowHideLoading(false);
      if (isInit) {
        this.addEditIntegration = !(result.total);
      }
      this.addEditIntegration = this.onboarding;
    });
  }

  mapSelectedCompanies(): void {
    this.cmpMapping.mapCompanyList.forEach((obj: any) => {
      const selectedCred = this.integrationsData.filter((x: any) => x._id === this.currentCredential);
      const params: any = {
        integrationName: this.currentIntegration.name, credentialName: selectedCred[0].name,
        credentialId: this.currentCredential, sourceCompanyName: obj.source.name,
        sourceCompanyId: obj.source._id, destCompanyName: obj.destination[this.companyActionParams.DisplayFields[0]],
        destCompanyId: obj.destination._id + ''
      };
      this.baseService.doRequest(`/api/integrations/companyMapping`, 'post', params).subscribe(() => {
        this.companyMappingList.push(obj.source.name);
        this.toast.sToast('success', `${obj.source.name} mapped.`);
        this.redirectMC();
      });
    });
  }

  importSelectedCompanies(): void {
    this.baseService.companyList = []; this.companyMappingList = []; this.failedMappingList = [];
    this.cmpMapping.selectedCompanies.forEach((obj: any) => {
      this.createCompany(obj);
    });
  }

  createCompany(obj: any): void {
    this.loaderService.display(true, `Importing ${obj.name}`);
    const newCompany = {
      name: obj.name, description: `Import from ${this.currentIntegration.title}`,
      source: 2, source_id: obj._id, tags: [`${this.currentIntegration.title}`]
    };
    this.companyService.createApiCompanyPost({body: newCompany}).subscribe((result: any) => {
      if (result._id === 'Company Exists With Same Name') {
        this.loaderService.display(false);
        this.failedMappingList.push(obj.name);
        this.toast.sToast('error', `${obj.name} company exist with the same name.`);
      } else {
        this.baseService.companyList.push(result);
        const selectedCred = this.integrationsData.filter((x: any) => x._id === this.currentCredential);
        const params: any = {
          integrationName: this.currentIntegration.name, credentialName: selectedCred[0].name,
          credentialId: this.currentCredential, sourceCompanyName: obj.name,
          sourceCompanyId: result._id, destCompanyName: obj[this.companyActionParams.DisplayFields[0]],
          destCompanyId: obj._id + ''
        };
        this.baseService.doRequest(`/api/integrations/companyMapping`, 'post', params).subscribe(() => {
          this.companyMappingList.push(obj.name);
          this.toast.sToast('success', `${obj.name} mapped.`);
          this.redirectIM();
        });
      }
    });
  }

  redirectMC(): void {
    if (this.cmpMapping.mapCompanyList.length === this.companyMappingList.length) {
      this.addEditIntegration = false;
      setTimeout(() => this.getIntegrationMapping(), 2000);
    }
  }

  redirectIM(): void {
    if (this.cmpMapping.selectedCompanies.length === (this.companyMappingList.length + this.failedMappingList.length) ) {
      this.addEditIntegration = false;
      setTimeout(() => {
        if (this.onboarding) {
          this.disableCreate = true;
        }
        this.getIntegrationMapping();
        this.updateCompanyList.emit({});
      }, 2000);
    }
  }

  /*Integration Mapping Table*/
  removeSelected(i: number): void {
    this.selectedCompanyList.forEach((obj: any, index: number) => {
      if (obj._id === this.cmpMapping.selectedCompanies[i]._id) {
        this.selectedCompanyList.splice(index, 1);
      }
    });
    this.cmpMapping.selectedCompanies.splice(i, 1);
  }

}
