<main>
  <ul class="">
    <li class="o-loading">
      <div class="">
        <span class="shimmer-box" style="width:30px;height:30px; border-radius: 50%"></span>
      </div>
      <div class="o-loading__body">
        <p>
          <span class="shimmer-box" style="width:80%;"></span>
          <span class="shimmer-box" style="width:80%;"></span>
          </p>
          <p>
            <span class="shimmer-box" style="width:50px;"></span>
          </p>
      </div>
    </li>
  </ul>
</main>
