import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-s-t-shimmer',
  templateUrl: './s-t-shimmer.component.html',
  styleUrls: ['./s-t-shimmer.component.scss']
})
export class STShimmerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
