/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Vulnerability } from '../models/vulnerability';
import { VulnerabilityCreate } from '../models/vulnerability-create';
import { VulnerabilityGetResp } from '../models/vulnerability-get-resp';

@Injectable({
  providedIn: 'root',
})
export class VulnerabilityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiVulnerabilityGet
   */
  static readonly GetAllApiVulnerabilityGetPath = '/api/vulnerability/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiVulnerabilityGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiVulnerabilityGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<VulnerabilityGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, VulnerabilityService.GetAllApiVulnerabilityGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VulnerabilityGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiVulnerabilityGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiVulnerabilityGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<VulnerabilityGetResp> {

    return this.getAllApiVulnerabilityGet$Response(params).pipe(
      map((r: StrictHttpResponse<VulnerabilityGetResp>) => r.body as VulnerabilityGetResp)
    );
  }

  /**
   * Path part for operation updateApiVulnerabilityPut
   */
  static readonly UpdateApiVulnerabilityPutPath = '/api/vulnerability/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiVulnerabilityPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiVulnerabilityPut$Response(params: {
    body: Vulnerability
  }): Observable<StrictHttpResponse<Vulnerability>> {

    const rb = new RequestBuilder(this.rootUrl, VulnerabilityService.UpdateApiVulnerabilityPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vulnerability>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiVulnerabilityPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiVulnerabilityPut(params: {
    body: Vulnerability
  }): Observable<Vulnerability> {

    return this.updateApiVulnerabilityPut$Response(params).pipe(
      map((r: StrictHttpResponse<Vulnerability>) => r.body as Vulnerability)
    );
  }

  /**
   * Path part for operation createApiVulnerabilityPost
   */
  static readonly CreateApiVulnerabilityPostPath = '/api/vulnerability/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiVulnerabilityPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiVulnerabilityPost$Response(params: {
    body: VulnerabilityCreate
  }): Observable<StrictHttpResponse<Vulnerability>> {

    const rb = new RequestBuilder(this.rootUrl, VulnerabilityService.CreateApiVulnerabilityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vulnerability>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiVulnerabilityPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiVulnerabilityPost(params: {
    body: VulnerabilityCreate
  }): Observable<Vulnerability> {

    return this.createApiVulnerabilityPost$Response(params).pipe(
      map((r: StrictHttpResponse<Vulnerability>) => r.body as Vulnerability)
    );
  }

  /**
   * Path part for operation getApiVulnerabilityIdGet
   */
  static readonly GetApiVulnerabilityIdGetPath = '/api/vulnerability/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiVulnerabilityIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiVulnerabilityIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Vulnerability>> {

    const rb = new RequestBuilder(this.rootUrl, VulnerabilityService.GetApiVulnerabilityIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vulnerability>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiVulnerabilityIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiVulnerabilityIdGet(params: {
    id: string;
  }): Observable<Vulnerability> {

    return this.getApiVulnerabilityIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Vulnerability>) => r.body as Vulnerability)
    );
  }

  /**
   * Path part for operation deleteApiVulnerabilityIdDelete
   */
  static readonly DeleteApiVulnerabilityIdDeletePath = '/api/vulnerability/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiVulnerabilityIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiVulnerabilityIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, VulnerabilityService.DeleteApiVulnerabilityIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiVulnerabilityIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiVulnerabilityIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiVulnerabilityIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
