import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {IntegrationsService} from '../../api/services/integrations.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, zoomInAnimation,
  zoomInOnEnterAnimation, zoomOutAnimation, zoomOutOnLeaveAnimation
} from 'angular-animations';
import {MyToastrService} from '../../_services/toastr.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {BaseRequestService} from '../../_services/base.service';
import {IntegrationActionsService} from '../../_services/integration-actions.service';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
  animations: [
    zoomInOnEnterAnimation(), zoomOutOnLeaveAnimation(),
    zoomOutAnimation(), zoomInAnimation(),
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation()
  ]
})

export class IntegrationsComponent implements OnInit {
  @Input() onboarding: any;
  @Input() filterType: any;
  @Output() importFinished = new EventEmitter();
  integrationsData: any[];
  integrations: any[];
  formElements: any = [];
  currentIntegration: any;
  integrationsCredentialsData: any[];
  integrationSearch = '';
  authenticateParamsData: any[];
  credData: any = {};
  newCred = false;
  typeHash: any = {string: 'text', number: 'number'};
  integrationFormValid = false;
  currentParams: any;
  currentParamHash: any = {};
  actionParams: any;
  constructor(private integrationsService: IntegrationsService, private toast: MyToastrService,
              private baseService: BaseRequestService, private integrationActionService: IntegrationActionsService,
              private confirmDialog: ConfirmDialogService, private loaderService: LoaderService) {
    integrationActionService.integrationOauthEvent.subscribe((value: any) => {
      console.log(value.code);
      this.validateOAuthCode(value.code);
    });
  }

  ngOnInit(): void {
    this.getAllIntegrations();
  }

  getAllIntegrations(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/integrations/`, 'get').subscribe((result: any) => {
      this.loaderService.display(false);
      if (this.onboarding && this.filterType) {
        result = result.filter((x: any) => x.integrationtype === this.filterType);
      }
      result.sort((a: any, b: any) => {
        const c = a.name; const d = b.name;
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
      this.integrations = result;
    });
  }

  getIntegrationsData(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/integrations/getcredentials?integrationName=${this.currentIntegration.name}`,
      'get').subscribe((result: any) => {
      this.loaderService.display(false);
      if (result.data && result.data.length) {
        this.integrationsData = result.data;
        this.credData = result.data[0];
        this.newCred = false;
      } else {
        this.integrationsData = [];
        this.newCred = true;
        this.currentIntegration.parameters.forEach((obj: any) => {
          this.credData[obj.name] = (obj.schema.type === 'checkbox') ? false : '';
          this.formElements.forEach((frm: any) => {
            if (frm.key === 'name') { frm.readonly = false; }
            if (frm.readonly && frm.key !== 'name') { this.credData[obj.name] = this.currentParamHash[obj.name]; }
          });
        });
      }
    });
  }

  setIntegration(product: any): void {
    this.currentIntegration = product;
    this.getAuthenticateParamsData(product.name);
  }

  getAuthenticateParamsData(integration: string): void {
    this.loaderService.display(true);
    this.baseService.doRequest(
      `/api/integrations/params?integrationName=${integration}`,
      'get').subscribe((result: any) => {
      this.loaderService.display(false);
      this.currentParams = result;
      this.formElements = [];
      const tmpFE: { label: any; key: any; required: any; type: any; }[] = [];
      result.parameters.forEach((obj: any) => {
        this.credData[obj.name] = (obj.schema.type === 'checkbox') ? false : '';
        if (obj.readonly) { this.currentParamHash[obj.name] = obj.value; }
        const ff: any = {label: obj.description, key: obj.name, required: obj.required, type: obj.schema.type};
        ff.readonly = (obj.readonly || obj.name === 'name');
        if (obj.schema.type === 'dropdown') {
          ff.options = obj.options;
        }
        tmpFE.push(ff);
      });
      this.formElements = tmpFE.slice();
      this.currentIntegration.parameters = result.parameters;
      this.currentIntegration.isoauth = result.isoauth;
      this.getIntegrationsData();
    });
  }

  validateOAuthCode(code: any): void {
    let ncsp: any;
    try {
      ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
      console.log(ncsp);
    } catch (e) {
      console.log('Error parsing AD config');
    }
    this.loaderService.display(true);
    this.baseService.doRequest(
      `/api/integrations/validateAuthcode`,
      'post', {code, isCsp: ncsp.isCsp}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result.status) {
        console.log(result.data);
        const cred: any = {credential: {...ncsp, ...result.data}};
        const data: any = {...cred};
        data.integrationName = this.currentIntegration.name;
        this.saveAAD(data);
        localStorage.removeItem('oAuthC');
        localStorage.removeItem('ncsp');
      } else {
        this.toast.sToast('error', result.data);
      }
    });
  }

  oAuthCredValidation(event: any): void {
    let clientId: any = ''; let url: any = '';
    this.currentParams.parameters.forEach((obj: any) => {
      if (obj.name === 'client_id') { clientId = obj.value; }
      if (event.isCsp) {
        if (obj.name === 'CSP') { url = obj.value; }
      } else {
        if (obj.name === 'NonCSP') { url = obj.value; }
      }
    });
    const rToken = uuidv4();
    const stateVar = btoa(`${window.location.host}@${rToken}@${clientId}`).replace(/=/g, '');
    const urlCSP = `${url}&state=${stateVar}`;
    localStorage.removeItem('oAuthC');
    localStorage.setItem('ncsp', JSON.stringify(event));
    this.baseService.o365window = window.open(urlCSP, 'popup', 'width=600,height=600');
    this.checkIsOAuthCode();
  }

  checkIsOAuthCode(): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD config');
      }
      const cred: any = {credential: {...ncsp}};
      cred.credential.code = oAuthC;
      const data: any = {...cred};
      data.integrationName = this.currentIntegration.name;
      this.saveAAD(data);
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => { this.checkIsOAuthCode(); }, 1000);
  }

  save(event: any): void {
    if (this.currentIntegration.name === 'AzureAD') {
      this.oAuthCredValidation(event); return;
    }
    this.loaderService.display(true, 'Saving credential...');
    this.baseService.doRequest(
      `/api/integrations/storecredentials`, 'post',
      {integrationName: `${this.currentIntegration.name}`, credential: event} ).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result.status) {
        const msg = (this.credData._id) ? 'Successfully updated' : 'Successfully created';
        this.toast.sToast('success', msg);
        this.newCred = false;
        setTimeout(() => this.getIntegrationsData(), 2000);
      } else {
        this.toast.sToast('error', result.errmsg);
      }
    });
  }

  saveAAD(event: any): void {
    if (this.currentIntegration.isoauth && !event.credential) {
      this.oAuthCredValidation(event); return;
    }
    let data: any; data = {integrationName: `${this.currentIntegration.name}`, credential: event.credential};
    if (this.currentIntegration.name === 'AzureAD' && event.credential.access_token) {
      data = event;
    }
    this.loaderService.display(true, 'Saving credential...');
    this.baseService.doRequest(
      `/api/integrations/storecredentials`, 'post',
      data ).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result.status) {
        const msg = (this.credData._id) ? 'Successfully updated' : 'Successfully created';
        this.toast.sToast('success', msg);
        this.newCred = false;
        setTimeout(() => this.getIntegrationsData(), 2000);
      } else {
        this.toast.sToast('error', result.errmsg);
      }
    });
  }

  cancel(): void {
    if (this.newCred) {
      this.newCred = false;
      if (this.integrationsData[0]) {
        this.credData = this.integrationsData[0];
      }
    } else {
      this.currentIntegration = undefined;
    }
  }

  setCred(cred: any): void {
    this.credData = cred;
  }

  addCred(): void {
    this.newCred = true;
    this.credData = {};
    this.currentIntegration.parameters.forEach((obj: any) => {
      this.credData[obj.name] = (obj.schema.type === 'checkbox') ? false : '';
      this.formElements.forEach((frm: any) => {
        if (frm.key === 'name') { frm.readonly = false; }
        if (frm.readonly && frm.key !== 'name') { this.credData[obj.name] = this.currentParamHash[obj.name]; }
      });
    });
  }

  delCred(cred: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete credential ' + cred.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, `Removing ${cred.name} credential`);
        this.baseService.doRequest(
          `/api/integrations/deletecredential?integrationName=${this.currentIntegration.name}&integrationId=${cred._id}`, 'post')
          .subscribe((result: any) => {
            if (result){
              this.toast.sToast('success', 'Removed successfully');
            }
            setTimeout(() => this.getIntegrationsData(), 2000);
        });

      }
    });
  }

  updateSelectionIndex(index: any): void {
    if (index === 1) {
      // Place holder for tab change event
    }
  }

  isFormValid($event: any): void {
    this.integrationFormValid = $event;
  }

  triggerOnboarding(): void {
    this.importFinished.emit({});
  }
}
