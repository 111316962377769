import { Component, OnInit, Input } from '@angular/core';
import {MyToastrService} from '../../_services/toastr.service';

export interface CategoryItem {
  category: string;
  value: number | string;
  risk: string;
  risk_value: number | string;
}
export interface RiskDataItem {
  title: string;
  toggle: boolean;
  categoryItems: CategoryItem[];
}

@Component({
  selector: 'app-report-card-table',
  templateUrl: './report-card-table.component.html',
  styleUrls: ['./report-card-table.component.scss']
})
export class ReportCardTableComponent implements OnInit {
  @Input()
  riskDataList!: RiskDataItem[];
  constructor(private toast: MyToastrService) {
    /*let s; s = '';
    if (this.riskDataList) {
      s = JSON.stringify(this.riskDataList);
      s = s.replace(/null/g, ' ');
      this.riskDataList = JSON.parse(s);
    }*/
  }

  ngOnInit(): void {
  }

  download(): void {
    this.toast.sToast('success', 'File download initiated');
  }
}
