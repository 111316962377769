<mat-card class="o-loading mb-2">
  <mat-card-header>
    <div class="o-loading__body">
        <span class="shimmer-box pl-3" style="width:32px;height:32px; "></span>
    </div>
  </mat-card-header>
  <br> <br>
  <mat-card-content class="o-loading__body">
    <table class="mat-table table w-100">
      <thead><tr class="mat-header-cell">
        <th><p class="shimmer-box" style="width:80%;"></p></th>
        <th><p class="shimmer-box" style="width:80%;"></p></th>
        <th><p class="shimmer-box" style="width:80%;"></p></th>
      </tr></thead>
      <tbody><tr >
        <td class="mat-cell"><p class="shimmer-box" style="width:80%;"></p></td>
        <td class="mat-cell"><p class="shimmer-box" style="width:80%;"></p></td>
        <td class="mat-cell"><p class="shimmer-box" style="width:80%;"></p></td>
        <td class="mat-cell"><p class="shimmer-box" style="width:80%;"></p></td>
        <td class="mat-cell"><p class="shimmer-box" style="width:80%;"></p></td>
      </tr></tbody>
    </table>
  </mat-card-content>
</mat-card>
