import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthenticationService} from '../../_services/authentication.service';
import {MyToastrService} from '../../_services/toastr.service';
import {BaseRequestService} from '../../_services/base.service';
import {LoaderService} from '../../_services/loader.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {ModalService} from '../../_services/modal.service';
import {FormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, delay, filter, map, takeUntil, tap} from 'rxjs/operators';
import {IntegrationActionsService} from '../../_services/integration-actions.service';

@Component({
  selector: 'app-application-baseline',
  templateUrl: './application-baseline.component.html',
  styleUrls: ['./application-baseline.component.scss']
})

export class ApplicationBaselineComponent implements OnInit, OnDestroy {
  isLoading = false;
  deniedAppCtrl = new FormControl();
  mandatoryAppCtrl = new FormControl();
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  @Input() currentCompany: any;
  @Input() mode: any;
  appBaselineColOptions: any;
  appBaselineQuery: any = {};
  currentAppBaseline: any = {name: '', os_type: 'windows', osname: '', deniedApplications: [], mandatoryApplications: []};
  dynamicColumns: any = [];
  mandatory: any;
  denied: any;
  deniedApplications: any = [];
  mandatoryApplications: any = [];
  private actEve: Subscription;
  constructor(public aS: AuthenticationService, public loaderService: LoaderService, private toast: MyToastrService,
              private modalService: ModalService, private integrationActionService: IntegrationActionsService,
              private baseService: BaseRequestService, private confirmDialog: ConfirmDialogService) {
    this.actEve = integrationActionService.applicationActionEvent.subscribe((val: any) => {
      this.triggerAB();
    });
    this.deniedAppCtrl.valueChanges
      .pipe(
        filter(value => !!value),
        debounceTime(500),
        tap(() => this.isLoading = true),
        map(value => {
          const company = (this.mode === 'company' && this.currentCompany && this.currentCompany._id) ? this.currentCompany._id : '';
          this.baseService.doRequest(`/api/company/dummy/getUniqueApplication`, 'post',
            {appName: value, companyid: company}).subscribe((result: any) => {
            this.loaderService.display(false);
            if (result) {
              result.sort((a: any, b: any) => {
                const c = a; const d = b; if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
              });
              this.deniedApplications = result;
            } else {
              this.deniedApplications = [];
              this.isLoading = false;
            }
          });
        }),
        delay(100)
      ).subscribe(filteredApps => {
        this.isLoading = false;
      }, error => {
      // no errors in our simulated example
      this.isLoading = false;
      // handle error...
    });
    this.mandatoryAppCtrl.valueChanges
      .pipe(
        filter(value => !!value),
        debounceTime(500),
        tap(() => this.isLoading = true),
        map(value => {
          const company = (this.mode === 'company' && this.currentCompany && this.currentCompany._id) ? this.currentCompany._id : '';
          this.baseService.doRequest(`/api/company/dummy/getUniqueApplication`, 'post',
            {appName: value, companyid: company}).subscribe((result: any) => {
            this.loaderService.display(false);
            if (result) {
              result.sort((a: any, b: any) => {
                const c = a; const d = b; if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
              });
              this.mandatoryApplications = result;
            } else {
              this.mandatoryApplications = [];
              this.isLoading = false;
            }
          });
        }),
        delay(100)
      ).subscribe(filteredApps => {
        this.isLoading = false;
      }, error => {
      // no errors in our simulated example
      this.isLoading = false;
      // handle error...
    });
  }

  ngOnDestroy(): void {
    this.actEve.unsubscribe();
  }
  deleteAppBaseline(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this rule ' + idata.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: boolean) => {
      if (res) {
        this.baseService.doRequest(`/api/applicationbaseline/${idata._id}`, 'delete')
          .subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => { this.getAppBaselineData(); }, 3000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }

  saveAppBaseline(): void {
    const url = '/api/applicationbaseline/';
    const method = (this.currentAppBaseline._id) ? 'put' : 'post';
    const msg = (this.currentAppBaseline._id) ? 'Updated successfully' : 'Added successfully';
    if (this.mode === 'company') {
      this.currentAppBaseline.companyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
    }
    this.loaderService.display(true, 'Saving application baseline rule');
    this.baseService.doRequest(url, method, this.currentAppBaseline).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result._id && result.c !== null && result.u !== null) {
        this.toast.sToast('success', msg);
        this.close();
        this.triggerAB(result._id);
      } else {
        this.toast.sToast('error', result._id);
      }
    });
  }

  triggerAB(ruleid?: any): void {
    const data = (ruleid) ? {ruleid} : {};
    this.loaderService.display(true, 'Processing application baseline...');
    this.baseService.doRequest(`/api/company/abc/processBaseLine`,
      'post', data).subscribe((result: any) => {
        this.loaderService.display(false);
        this.toast.sToast('success', 'Process initiated successfully');
    });
  }

  actionCalback(idata: any): void {
    if (idata.action.text === 'Edit') {
      this.currentAppBaseline = idata.row;
      this.currentAppBaseline.isLightWeightMandatory = (idata.row.isLightWeightMandatory) ? idata.row.isLightWeightMandatory : false;
      this.snav.open();
    } else if (idata.action.text === 'Delete') {
      this.deleteAppBaseline(idata.row);
    }
  }

  addEditAppBaseline(): void {
    this.currentAppBaseline = {name: '', os_type: 'windows', osname: '', deniedApplications: [],
      mandatoryApplications: [], isLightWeightMandatory: false};
    this.snav.open();
  }

  close(status?: any): void {
    if (status) { return; }
    this.currentAppBaseline = {name: '', os_type: 'windows', osname: '', deniedApplications: [],
      mandatoryApplications: [], isLightWeightMandatory: false};
    this.snav.close();
    this.getAppBaselineData();
  }

  ngOnInit(): void {
    this.dynamicColumns = [
      { col: 'u', header: 'Last updated', colFilters: {type: 'text', hKey: false}, isKeyword: false, noFilter: true },
      { col: 'name', header: 'Name', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      { col: 'deniedApplications', header: 'Denied Application',  isKeyword: true, filter: 'ellipse:70' },
      { col: 'mandatoryApplications', header: 'Mandatory Application', isKeyword: true, filter: 'ellipse:70' },
      { col: 'os_type', header: 'OS Type', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      { col: 'osname', header: 'OS', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
    ];
    this.getAppBaselineData();
  }

  getAppBaselineData(): void {
    if (this.mode === 'company' && this.currentCompany && this.currentCompany._id) {
      this.appBaselineQuery = {
        query: {
          bool: {
            must: [
              {match: {'companyRef.id.keyword': this.currentCompany._id}},
              {
                bool: {
                  should: [
                    {exists: {field: 'deniedApplications'}}, {exists: {field: 'mandatoryApplications'}}
                  ]
                }
              }
            ]
          }
        }
      };
    } else {
      this.appBaselineQuery = {
        query: {
          bool: {
            must: [
              {
                bool: {
                  should: [
                    {exists: {field: 'deniedApplications'}}, {exists: {field: 'mandatoryApplications'}}
                  ]
                }
              }
            ],
            must_not: [{exists: {field: 'companyRef.id.keyword'}}]
          }
        }
      };
    }
    this.appBaselineColOptions = {};
    setTimeout(() => {
      this.appBaselineColOptions = {
        id: 'appBaselineColOptions',
        title: 'Application Baseline Rules',
        refresh: true,
        cFilter: true,
        pSize: 5,
        pList: [1, 5, 10, 25, 50, 100, 200],
        pagination: true,
        loading: true,
        customCols: true,
        showAction: true,
        add: true,
        faClass: 'ApplicationBaseline',
        serverSide: {
          service: 'applicationBaselineService',
          fn: 'getAllApiApplicationbaselineGet',
          q: this.appBaselineQuery, sort: [{u: {order: 'desc'}}]
        },
        lMsg: 'Getting application baseline...',
        local: false,
        dataList: [],
        columns: this.dynamicColumns,
        actionMenuItems: [
          {text: 'Edit', icon: 'edit', isGlobal: false, callback: 'detailFn'},
          {text: 'Delete', icon: 'delete', isGlobal: false, callback: 'detailFn'},
        ]
      };
    });
  }

  closeDM(): void {
    this.modalService.close('deniedMandatoryApplications');
  }

  openDM(): void {
    this.modalService.open('deniedMandatoryApplications');
  }
}
