<div class="over-flow w-100">
  <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
    <thead>
      <tr class="mat-header-row">
        <th mat-sort-header="Name" class="mat-header-cell text-uppercase">Name</th>
        <th mat-sort-header="Port" class="mat-header-cell text-uppercase">Port</th>
        <th mat-sort-header="TicketID" class="mat-header-cell text-uppercase">TicketID</th>
      </tr>
    </thead>
    <tbody>
      <tr class="mat-row" *ngFor="let asset of sortedData;">
        <td class="mat-cell">{{asset.name}}</td>
        <td class="mat-cell">{{asset.port}} </td>
        <td class="mat-cell">{{asset.ticketId}}</td>
      </tr>
    </tbody>
  </table>
</div>
