import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.scss']
})

export class EvidenceComponent implements OnInit {
  @Input() solution: any;
  @Input() prodHash: any;
  assets: any = [];

  constructor() {
  }

  ngOnInit(): void {
    this.assets = this.solution.assets;
  }
}
