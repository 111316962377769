import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pnf',
  templateUrl: './pnf.component.html',
  styleUrls: ['./pnf.component.scss']
})
export class PnfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
