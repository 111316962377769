<div class="over-flow w-100">
  <!--<mat-list dense class="evidence bor-1">
    <mat-list-item class="bb-1" *ngFor="let asset of assets;">
      <mat-icon mat-list-icon>devices</mat-icon>
      <div mat-line class="tprime">{{asset.name}}</div>
      <div mat-line>
        <span class="mt-1"><strong>Evidence: </strong>
          <span *ngIf="solution.remediation_type === 'os'" class="mr15"> Missing windows update - {{solution.fix}}</span>
          <ng-container *ngIf="asset.evidence.path || asset.evidence.version">
            <span>Path: {{asset.evidence.path}}</span>
            <span>Version: {{asset.evidence.version}}</span>
          </ng-container>
        </span>
      </div>
    </mat-list-item>
  </mat-list>-->
  <mat-card class="mat-elevation-z0" *ngFor="let asset of assets;">
    <mat-card-header>
      <mat-card-title>{{asset.name}}</mat-card-title>
      <mat-card-subtitle>Evidence details</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <span *ngIf="solution.remediation_type === 'os'" class="fs1 fw3 mr-2"> Missing windows update - {{solution.fix}}</span>
      <table class="rounded-corner table w-100 bor-1"
             *ngIf="asset.evidence && asset.evidence.productRef && prodHash[asset.evidence.productRef]">
        <tbody>
          <tr><td class="w-30p fs1 fw3">Name</td><td>{{prodHash[asset.evidence.productRef].full_name}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Path</td><td>{{prodHash[asset.evidence.productRef].path}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Uninstall Path</td><td>{{prodHash[asset.evidence.productRef].uninstall_string}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Install Date</td><td>{{prodHash[asset.evidence.productRef].install_date}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Identifying Number</td><td>{{prodHash[asset.evidence.productRef].identifying_number}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Version</td><td>{{prodHash[asset.evidence.productRef].version}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Vulnerability</td><td>{{prodHash[asset.evidence.productRef].vulcount}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Critical</td><td><span classb></span>{{prodHash[asset.evidence.productRef].critical_vuls_count}}</td></tr>
          <tr><td class="w-30p fs1 fw3">High</td><td>{{prodHash[asset.evidence.productRef].high_vuls_count}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Medium</td><td>{{prodHash[asset.evidence.productRef].medium_vuls_count}}</td></tr>
          <tr><td class="w-30p fs1 fw3">Low</td><td>{{prodHash[asset.evidence.productRef].low_vuls_count}}</td></tr>
        </tbody>
      </table>
      <span *ngIf="!asset.evidence || !asset.evidence.productRef || !prodHash[asset.evidence.productRef]"
            class="fs1 fw3 mr-2"> No evidence found!</span>
    </mat-card-content>
  </mat-card>
</div>
