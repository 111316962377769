import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppFilterPipeModule} from '../_filters/app.filter-pipe.module';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {PnfComponent} from './pnf/pnf.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {STableComponent} from './s-table/s-table.component';
import {ModalComponent} from './modal.component';
import {TableComponent} from './table/table.component';
import {SShimmerComponent} from './s-shimmer/s-shimmer.component';
import {ReportCardTableComponent} from './report-card-table/report-card-table.component';
import {SExpTableComponent} from './s-exp-table/s-exp-table.component';
import {DynamicFormComponent} from './forms/dynamic-form/dynamic-form.component';
import {DynamicControlComponent} from './forms/dynamic-control/dynamic-control.component';
import {CustomCalendarComponent} from './forms/custom-calendar/custom-calendar.component';
import {PillCardComponent} from './pill-card/pill-card.component';
import {SCrudTComponent} from './s-crud-t/s-crud-t.component';
import {STShimmerComponent} from './s-t-shimmer/s-t-shimmer.component';
import {IntegrationActionComponent} from './integration-action/integration-action.component';
import {EvidenceComponent} from './evidence/evidence.component';
import {JobDetailsComponent} from './job-details/job-details.component';
import {IntegrationsComponent} from './integrations/integrations.component';
import {CompanyMappingComponent} from './integrations/company-mapping/company-mapping.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {AlertsComponent} from './alerts/alerts.component';
import {AlertsNotificationComponent} from './alerts-notification/alerts-notification.component';
import {ApplicationBaselineComponent} from './application-baseline/application-baseline.component';
import { VulnerabilityStatsComponent } from './vulnerability-stats/vulnerability-stats.component';
import { NonpsaCompanyMappingComponent } from './integrations/nonpsa-company-mapping/nonpsa-company-mapping.component';
import { NetworksScanComponent } from './networks-scan/networks-scan.component';
import { NetworkassetComponent } from './networkasset/networkasset.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    PnfComponent, UnauthorizedComponent,
    STableComponent,
    ModalComponent,
    TableComponent,
    SShimmerComponent,
    ReportCardTableComponent,
    SExpTableComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    CustomCalendarComponent,
    PillCardComponent,
    SCrudTComponent,
    STShimmerComponent,
    IntegrationActionComponent,
    EvidenceComponent,
    JobDetailsComponent,
    IntegrationsComponent,
    CompanyMappingComponent,
    AlertsComponent,
    AlertsNotificationComponent,
    ApplicationBaselineComponent,
    VulnerabilityStatsComponent,
    NonpsaCompanyMappingComponent,
    NetworkassetComponent,
    NetworksScanComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    AppFilterPipeModule,
    NgxMatSelectSearchModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    ConfirmDialogComponent,
    PnfComponent, UnauthorizedComponent,
    STableComponent,
    TableComponent,
    ModalComponent,
    SShimmerComponent, ReportCardTableComponent,
    SExpTableComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    CustomCalendarComponent, PillCardComponent, SCrudTComponent, STShimmerComponent,
    IntegrationActionComponent, EvidenceComponent, JobDetailsComponent, IntegrationsComponent,
    CompanyMappingComponent, AlertsComponent, AlertsComponent, AlertsNotificationComponent,
    ApplicationBaselineComponent, NetworksScanComponent, NetworkassetComponent
  ],
  entryComponents: [ConfirmDialogComponent, IntegrationActionComponent]
})

export class SharedModule {
}
