<mat-card class="p-0 mb-0 ibtn" *ngIf="riskDataList && riskDataList.length">
  <mat-card-content>
    <mat-list dense class="">
      <ng-container *ngFor="let riskList of riskDataList">
        <mat-list-item>
          <mat-icon class="mat-24 pointer" *ngIf="!riskList.toggle" (click)="riskList.toggle = !riskList.toggle">chevron_right
          </mat-icon>
          <mat-icon class="mat-24 pointer" *ngIf="riskList.toggle" (click)="riskList.toggle = !riskList.toggle">expand_more
          </mat-icon>
          <h3 class="ml-2 mat-body-1 n-dark mb-0">{{riskList.title}}</h3>
        </mat-list-item>
        <div class="row mx-2">
          <div class="col-lg-12">
            <table class="table info-c-table w-100 mb-3" *ngIf="riskList.toggle">
              <thead>
              <tr>
                <th class="px-2 pt-1 w-25">Category</th>
                <th class="px-2 pt-1 w-50">Current Value</th>
                <th class="px-2 pt-1 w-25 text-center">Risk Factor</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let rec of riskList.categoryItems; let odd = odd;" [ngClass]="{ 'r-alt': odd}">
                <td class="px-2 pt-1">{{rec.category}}</td>
                <td class="px-2 pt-1">{{rec.value}}
                  <button class="ml-1 tprime" matTooltip="Download Report" (click)="download()" mat-icon-button="">
                    <mat-icon class="mat-24">file_download</mat-icon>
                  </button></td>
                <td class="px-2 pt-1 text-center">
                  <span [matTooltip]="rec.risk" *ngIf="rec.risk === 'not-applicable'"
                        class="badge badge-pill small sgray ">&nbsp;</span>
                  <span [matTooltip]="rec.risk" *ngIf="rec.risk === 'compliant'"
                        class="badge badge-pill small scompliant">&nbsp;</span>
                  <span [matTooltip]="rec.risk" *ngIf="rec.risk === 'critical'"
                        class="badge badge-pill small scritical">&nbsp;</span>
                  <span [matTooltip]="rec.risk" *ngIf="rec.risk === 'high'"
                        class="badge badge-pill small shigh">&nbsp;</span>
                  <span [matTooltip]="rec.risk" *ngIf="rec.risk === 'medium'"
                        class="badge badge-pill small smedium">&nbsp;</span>
                  <span [matTooltip]="rec.risk" *ngIf="rec.risk === 'low'"
                        class="badge badge-pill small slow">&nbsp;</span>
                  <span [matTooltip]="rec.risk" *ngIf="rec.risk === 'info'"
                        class="badge badge-pill small sinfo">&nbsp;</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </mat-list>
  </mat-card-content>
</mat-card>
