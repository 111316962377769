import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../_services/modal.service';
import {LoaderService} from '../../_services/loader.service';
import {CommonService} from '../../_services/common.services';
import {BaseRequestService} from '../../_services/base.service';
import {IntegrationActionsService} from '../../_services/integration-actions.service';
import {MyToastrService} from '../../_services/toastr.service';

@Component({
  selector: 'app-integration-action',
  templateUrl: './integration-action.component.html',
  styleUrls: ['./integration-action.component.scss']
})

export class IntegrationActionComponent implements OnInit {
  @Input() inputActionData: any;
  localActionData: any;
  integrationActionsWidth: any = 800;
  credListData: any = [];
  sView = 'getIntegrations';
  integration: any = {actionData: {}};
  integrationHash: any = [];
  integrationMappingHash: any = [];
  formElements: any = [];
  actionParams: any;
  currentActions: any;
  Objectkeys = Object.keys;
  actionParamsList: any;
  constructor(public modalService: ModalService, private loaderService: LoaderService,
              private integrationActionsService: IntegrationActionsService, private toast: MyToastrService,
              private baseService: BaseRequestService, private commonService: CommonService) {
    integrationActionsService.integrationActionPopup.subscribe((res: any) => {
      if (res.value) {
        setTimeout(() => {
          // this.modalService.open('integrationActions');
          this.getAllIntegrations();
        });
      }
    });
  }

  ngOnInit(): void {
    this.localActionData = {...this.inputActionData};
  }

  onDismiss(): void {
    this.modalService.close('integrationActions');
    this.integrationActionsService.integrationActionShow = false;
  }

  getAllIntegrations(): void {
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(`/api/integrations`, 'get').subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      result.forEach((obj: any) => {
        this.integrationHash[obj.name] = obj;
      });
      this.getIntegrationMapping(true);
    });
  }

  getIntegrationMapping(isInit?: boolean): void {
    this.loaderService.Modeldisplay(true, 'Getting mapped integrations...');
    const query = {
      query: {bool: {must: [{match: {'sourceCompanyId.keyword': this.commonService.currentCompany._id + ''}}]}}
    };
    const q = JSON.stringify(query); const skip = 0; const limit = 100;
    this.baseService.doRequest(`/api/integrations/companyMapping`,
      'get', null, {q, skip, limit}).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      if (result.total === 0 && isInit) {
        this.toast.sToast('error', 'No integration mapped to this company. ');
        this.modalService.close('integrationActions'); return;
      }
      result.data.forEach((obj: any) => { this.integrationMappingHash[obj.credentialId] = obj; });
      this.credListData = result.data;
      if (isInit) { this.modalService.open('integrationActions'); }
    });
  }

  getActions($event: any): void {
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(
      `/api/integrations/actions?integrationName=${this.integrationMappingHash[$event].integrationName}`,
      'get').subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.currentActions = result.actions;
    });
  }

  getAction(): void {
    this.getActionParams();
  }

  getActionParams(): void {
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.integrationMappingHash[this.integration.credentialId].integrationName}&actionName=${this.integration.action}`,
      'get').subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.actionParams = result;
      this.processFields(result);
      this.sView = 'actionParams';
    });
  }

  processFields(result: any): void {
    this.formElements = [];
    this.integration.actionData = (this.integration.actionData) ? this.integration.actionData : [];
    const tmpAP: { label: any; key: any; required: any; type: any; }[] = [];
    result.parameters.forEach(async (obj: any) => {
      const tFF: any = {label: obj.description, key: obj.name, required: obj.required, type: obj.schema.type};
      tFF.callback = (obj.callback);
      if (obj.source && !obj.depends) {
        this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
          const requestParams: any = {};
          obj.subParams = result;
          if (subObj.parameters) {
            subObj.parameters.forEach((pObj: any) => {
              requestParams[pObj.name] = pObj.example;
            });
          }
          this.loaderService.Modeldisplay(true, `Executing ${subObj.name}`);
          this.executeAction(subObj, requestParams).then((subOut: any) => {
            this.processParams(subOut.data).then((edata: any) => {
              this.loaderService.Modeldisplay(false);
              obj.subOut = edata;
              tFF.options = [];
              edata.forEach((val: any) => {
                tFF.options.push({key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue]});
              });
            });
          });
        });
      } else if (obj.source && obj.depends && this.integration.actionData[obj.depends[0]]) {
        this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
          const requestParams: any = {};
          obj.subParams = result;
          if (subObj.parameters) {
            subObj.parameters.forEach((pObj: any) => {
              requestParams[pObj.name] = pObj.example;
            });
          }
          requestParams[obj.source.parameter] = this.integration.actionData[obj.depends[0]];
          this.loaderService.Modeldisplay(true, `Executing ${subObj.name}`);
          this.executeAction(subObj, requestParams).then((subOut: any) => {
            this.processParams(subOut.data).then((edata: any) => {
              this.loaderService.Modeldisplay(false);
              obj.subOut = edata;
              tFF.options = [];
              edata.forEach((val: any) => {
                tFF.options.push({key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue]});
              });
            });
          });
        });
      }
      tmpAP.push(tFF);
      if (obj.maptokey) {
        this.integration.actionData[obj.name] = this.integrationMappingHash[this.integration.credentialId][obj.maptokey];
      }
      if (this.inputActionData[obj.name] && !this.integration.actionData[obj.name]) {
        this.integration.actionData[obj.name] = this.inputActionData[obj.name];
      } else if (!this.inputActionData[obj.name] && this.integration.actionData[obj.name]) {
        this.integration.actionData[obj.name] = this.integration.actionData[obj.name];
      } else {
        this.integration.actionData[obj.name] = this.localActionData[obj.name];
      }
    });
    this.formElements = tmpAP.slice();
    this.integration.actionParams = result.parameters;
  }

  actionChange($event: any): void {
    if ($event && $event !== '') {
      setTimeout(() => {
        this.processFields(this.actionParams);
      }, 2000 );
    }
  }

  getSubActionParams(actionName: string): Promise<any> {
    return this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.integrationMappingHash[this.integration.credentialId].integrationName}&actionName=${actionName}`, 'get').toPromise();
  }

  executeAction(param: any, requestparams: any): Promise<any> {
    const params: any = {
      integrationName: this.integrationMappingHash[this.integration.credentialId].integrationName,
      integrationId: this.integration.credentialId,
      params: { action: {name: param.name, destination: param.destination, verb: param.verb}, params: {requestparams} }
    };
    return this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params).toPromise();
  }

  async processParams(data: any): Promise<any> {
    const value: any = [];
    await data.row_values.forEach((obj: any) => {
      const obj2: any = {};
      data.column_definitions.forEach((obj1: any, index: number) => {
        const key = this.Objectkeys(obj1)[0];
        obj2[key] = obj[index];
      });
      value.push(obj2);
    });
    return value;
  }

  save($event: any): void {

  }

  cancel(): void {

  }

  saveAction(): void {
    this.loaderService.Modeldisplay(true);
    this.executeAction(this.actionParams, this.integration.actionData).then((actionOut: any) => {
      this.loaderService.Modeldisplay(false);
      this.toast.sToast('success', `Successfully executed. ${actionOut.data.id} created. `);
      this.onDismiss();
    });
  }
}
