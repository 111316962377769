<div class="network-container" *ngIf="mode === 'company'">
  <mat-sidenav-container class="remedia-sidenav-container " [ngClass]="{'h-100vh': mode !== 'assets'}"
                         (backdropClick)="close('backdrop')">
    <mat-sidenav-content>
      <mat-card class="table-card mb-2">
        <mat-toolbar class="table-tools" role="heading">
          <div fxFlex fxLayout fxLayoutAlign="start">
            <span class="fs1">Network Scan <span class="fw3" *ngIf="sortedData.length">
          ({{sortedData.length}}) </span></span>
          </div>
          <div fxFlex fxLayout fxLayoutAlign="end">
            <div fxLayout fxLayoutGap="15px" class="navigation-items">
              <form fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field class="search-tool fs1" floatLabel="never">
                  <input matInput name="valuesearch" [(ngModel)]="rmFilter" placeholder="Filter"
                         autocomplete="off">
                  <button mat-button *ngIf="!rmFilter || rmFilter === ''" aria-label="Search" matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <button mat-button *ngIf="rmFilter" matSuffix mat-icon-button aria-label="Clear"
                          (click)="rmFilter='';">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <button class="ml-1" (click)="initRP()" mat-icon-button="">
                  <mat-icon>refresh</mat-icon>
                </button>
              </form>
            </div>
          </div>
        </mat-toolbar>
        <mat-card-content>
          <div class="overflow-auto" *ngIf="mode === 'company'">
            <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
              <thead>
                <tr class="mat-header-row">
                  <th mat-sort-header="vul_id" class="mat-header-cell text-center">CVE</th>
                  <th mat-sort-header="title" class="mat-header-cell text-uppercase">Title</th>
                  <th mat-sort-header="severity" class="mat-header-cell text-uppercase">Severity</th>
                  <th mat-sort-header="score" class="mat-header-cell text-uppercase">CVSS Score</th>
                  <th mat-sort-header="assets" class="mat-header-cell text-uppercase">Asset(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of sortedData | search: rmFilter" class="mat-row">
                  <td class="mat-cell">{{data.vul_id}}</td>
                  <td class="mat-cell"><span (click)="cs.copyClip(data.title)" [matTooltip]="data.title">{{data.title | ellipsis: 35}}</span></td>
                  <td class="mat-cell">
                    <span *ngIf="data.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
                    <span *ngIf="data.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
                    <span *ngIf="data.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
                    <span *ngIf="data.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
                    <span *ngIf="data.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
                  </td>
                  <td class="mat-cell">{{data.score}}</td>
                  <td class="mat-cell">
                    <span class="pointer pull-up bor-1 px-2" *ngIf="data.assets" (click)="showHosts(data)">
                      {{data.assets.length}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
    <mat-sidenav #sidenav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p">
      <mat-card class="w-100" *ngIf="mode === 'company'">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="sidenav.close();">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw2 mr-4">Assets</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflow-auto" *ngIf="currentNs && currentNs.assets">
          <app-networkasset [solution]="currentNs"></app-networkasset>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
<div class="network-global-container" *ngIf="mode === 'global'">
  <mat-sidenav-container class="global-network-sidenav-container " [ngClass]="{'h-100vh': mode !== 'assets'}"
                         (backdropClick)="close('backdrop')">
    <mat-sidenav-content>
      <mat-card class="table-card mb-2">
        <mat-toolbar class="table-tools" role="heading">
          <div fxFlex fxLayout fxLayoutAlign="start">
            <span class="fs1">Network Scan
              <span class="fw3" *ngIf="GsortedData.length"> ({{GsortedData.length}}) </span>
            </span>
          </div>
          <div fxFlex fxLayout fxLayoutAlign="end">
            <div fxLayout fxLayoutGap="15px" class="navigation-items">
              <form fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field class="search-tool fs1" floatLabel="never">
                  <input matInput name="valuesearch" [(ngModel)]="rmFilter" placeholder="Filter"
                         autocomplete="off">
                  <button mat-button *ngIf="!rmFilter || rmFilter === ''" aria-label="Search" matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <button mat-button *ngIf="rmFilter" matSuffix mat-icon-button aria-label="Clear"
                          (click)="rmFilter='';">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <button class="ml-1" (click)="initRP()" mat-icon-button="">
                  <mat-icon>refresh</mat-icon>
                </button>
              </form>
            </div>
          </div>
        </mat-toolbar>
        <mat-card-content>
          <div class="overflow-auto" *ngIf="mode === 'global'">
            <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
              <thead>
              <tr class="mat-header-row">
                <th mat-sort-header="CVE" class="mat-header-cell text-center">CVE</th>
                <th mat-sort-header="Title" class="mat-header-cell text-uppercase">Title</th>
                <th mat-sort-header="Severity" class="mat-header-cell text-uppercase">Severity</th>
                <th mat-sort-header="CVSS Score" class="mat-header-cell text-uppercase">CVSS Score</th>
                <th mat-sort-header="assets" class="mat-header-cell text-uppercase">Companies(s)</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let gNScan of GsortedData | search: rmFilter" class="mat-row">
                  <td class="mat-cell"> {{gNScan.vul_id}}</td>
                    <td class="mat-cell">{{gNScan.title}}
                    </td>
                  <td class="mat-cell">
                    <span *ngIf="gNScan.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
                    <span *ngIf="gNScan.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
                    <span *ngIf="gNScan.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
                    <span *ngIf="gNScan.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
                    <span *ngIf="gNScan.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
                  </td>
                  <td class="mat-cell">{{gNScan.score}}</td>
                  <td class="mat-cell">
                    <span class="pointer pull-up bor-1 px-2" *ngIf="gNScan.companies" (click)="showCompanies(gNScan)">
                      {{gNScan.companies.length}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
    <mat-sidenav #sidenav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p">
      <mat-card class="w-100" *ngIf="mode === 'global'">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="sidenav.close();">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw2 mr-4">Companies</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflow-auto" *ngIf="currentNs && currentNs.companies">
          <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
              <thead>
              <tr class="mat-header-row">
                <th mat-sort-header="companyName" class="mat-header-cell text-uppercase">Company</th>
                <th mat-sort-header="title" class="mat-header-cell text-uppercase">Title</th>
                <th mat-sort-header="severity" class="mat-header-cell text-uppercase">Severity</th>
                <th mat-sort-header="score" class="mat-header-cell text-uppercase">CVSS Score</th>
                <th mat-sort-header="assets" class="mat-header-cell text-uppercase">Asset(s)</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let data of currentNs.companies|search:rmFilter">
                <tr class="mat-row">
                  <td class="mat-cell">
                    <div class="d-flex align-middle">
                      <span class="p-2">{{data.companyName}}</span>
                    </div>
                  </td>
                  <td class="mat-cell"><span (click)="cs.copyClip(data.title)" [matTooltip]="data.title">{{data.title | ellipsis: 35}}</span></td>
                  <td class="mat-cell">
                    <span *ngIf="data.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
                    <span *ngIf="data.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
                    <span *ngIf="data.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
                    <span *ngIf="data.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
                    <span *ngIf="data.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
                  </td>
                  <td class="mat-cell">{{data.score.cvss_score}}</td>
                  <td class="mat-cell">
                    <span class="pointer pull-up bor-1 px-2" *ngIf="data.assets" (click)="data.visible = true; showAssets(data)">
                      {{data.assets.length}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="data.visible">
                  <td colspan="5">
                    <mat-card>
                      <button class="float-right mt--10" mat-icon-button aria-label="close modal icon"
                              matTooltip="Close" (click)="data.visible = !data.visible">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-card-header>
                        <mat-card-title class="fs1 fw2">Asset Details</mat-card-title>
                      </mat-card-header>
                      <mat-card-content class="overflow-auto max-height-200">
                        <app-networkasset *ngIf="currentGNs && currentGNs.assets" [solution]="currentGNs"></app-networkasset>
                      </mat-card-content>
                    </mat-card>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>


