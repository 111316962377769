<mat-card>
  <mat-card-content>
    <span #customValDiv>
      <div *ngFor="let item of inputarray;" [ngClass]="{'divborder': item.text===selectedArry[item.text]}" class="calItem"
       (click)="itemClick(item.value)">
          {{item.text}}
      </div>
    </span>
  </mat-card-content>
  <mat-card-actions>
    <button type="button"  color="warn" mat-button (click)="onCancel()">Cancel</button>
    <button type="button" color="primary" mat-button (click)="setValues()">Set</button>
  </mat-card-actions>
</mat-card>
