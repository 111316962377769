<div class="remedia-container">
  <mat-sidenav-container class="remedia-sidenav-container h-100vh" (backdropClick)="close('backdrop')">
    <mat-sidenav-content>
      <app-s-crud-t (hyperlinkCallback)="viewVuls($event)"
              *ngIf="vulStatsColOptions && vulStatsColOptions.columns && !vulStatsColOptions.hideTable"
              [tOption]="vulStatsColOptions"></app-s-crud-t>
    </mat-sidenav-content>
    <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p">
      <mat-card>
        <button class="position-absolute r-1p"  aria-label="close modal icon" matTooltip="Close"
                (click)="close();" mat-icon-button="">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw3">{{currentVulStats.name}} - Vulnerabilities</mat-card-title>
          <mat-card-subtitle>{{currentVulStats.description}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <app-s-crud-t (totalCallback)="totalCallbackCheck($event)"
              (hyperlinkCallback)="viewVuls($event)"
              *ngIf="vulsColOptions && vulsColOptions.columns && aS.hasPermission('company', 'getvulnerabilitystats')"
              [tOption]="vulsColOptions"></app-s-crud-t>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
