import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControlService} from 'src/app/_services/form-control.service';
import {FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [FormControlService]
})

export class DynamicFormComponent implements OnInit {
  @Input() fnBtnName = 'Save';
  @Input() closeBtnName = 'Close';
  @Input() isCloseBtn = true;
  @Input() isSaveBtn = true;
  @Input() listOfFormElements: any = [];
  @Output() saveCallBack = new EventEmitter();
  @Output() cancelCallBack = new EventEmitter();
  // @Input() selectedValue: any;
  @Output() valueUpdate = new EventEmitter();
  form!: FormGroup;
  @Input() Valuesoutput: {} = {};
  @Output() outputValue: {} = {};
  payLoad = '';

  constructor(private formService: FormControlService, public toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.form = this.formService.toFormGroup(this.listOfFormElements);
  }

  Save(form: any): void {
    const frmValues = form.value;
    if (frmValues.netaName && !frmValues.netaName.replace(/\s/g, '').length) {
      this.toastr.error('Input cannot contain only whitespaces');
    } else {
      this.saveCallBack.emit(frmValues);
    }
  }

  Cancel(): void {
    this.cancelCallBack.emit();
  }

  fieldValChange($event: any): void {
    this.valueUpdate.emit($event);
  }

  onSubmit(): void {
    // this.form.value["SelectedDropdown"] = this.selectedValue.value;
    // delete  this.form.value.undefined;
    // @ts-ignore
    this.payLoad = JSON.stringify(this.form.value);
  }

}
