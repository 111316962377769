import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseRequestService} from './base.service';
import {AgentService} from '../api/services/agent.service';
import {MyToastrService} from './toastr.service';

@Injectable({providedIn: 'root'})
export class CommonService {
  previousLink = '';
  modifiedDiscoverItems: any;
  Objectkeys = Object.keys;
  complianceMaster: any = {};
  securityMatrix: any = {};
  angularEditorChange: Subject<object> = new Subject<object>();
  bwListChange: Subject<object> = new Subject<object>();
  captureScreenChange: Subject<object> = new Subject<object>();
  initCaptureChange: Subject<object> = new Subject<object>();
  openSideNavChange: Subject<object> = new Subject<object>();
  selectedSiteChange: Subject<any> = new Subject<any>();
  rmRefreshEvent: Subject<any> = new Subject<any>();
  selectedNode = {};
  networkDeviceReloadChange: Subject<any> = new Subject<any>();
  addCompanyChange: Subject<any> = new Subject<any>();
  addSiteChange: Subject<any> = new Subject<any>();
  checkNode = {};
  timeZoneList: any;
  currentTimezone: any;
  unauth: any;
  currentCompany: any;
  ccId: any;
  modalHolder: string[] = [];
  currentAssessment: any;
  currentCompanyView: string;

  constructor(public baseRequestService: BaseRequestService, private agentService: AgentService,
              private toast: MyToastrService) {
  }

  isPrivateIP(ip: string): boolean {
    const parts = ip.split('.');
    return parts[0] === '10' ||
      (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) ||
      (parts[0] === '192' && parts[1] === '168');
  }

  compare(a: number | string, b: number | string, isAsc: boolean): any {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  buildQuery(data: { [x: string]: any; }): any {
    const baseQuery = {query: {bool: {must: []}}};
    this.Objectkeys(data).forEach(obj => {
      const tmpObj = {match: {}};
      // @ts-ignore
      tmpObj.match[obj] = data[obj];
      // @ts-ignore
      baseQuery.query.bool.must.push(tmpObj);
    });
    return baseQuery;
  }
  onlyUnique(value: any, index: any, self: string | any[]): boolean {
      return self.indexOf(value) === index;
  }
  reloadDevice(deviceId: any): void {
    this.networkDeviceReloadChange.next(deviceId);
  }

  percentCalculator(x: number, y: number): number {
    return Math.floor((y / x) * 100);
  }

  copyClip(cmd: string): void {
    const el = document.createElement('textarea');
    el.value = cmd;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toast.sToast('success', 'Copied to clipboard');
  }

  getTimeZone(): void {
    const offset = (new Date()).getTimezoneOffset();
    const timezones = {
    '-12': 'Pacific/Kwajalein',
    '-11': 'Pacific/Samoa',
    '-10': 'Pacific/Honolulu',
    '-9': 'America/Juneau',
    '-8': 'America/Los_Angeles',
    '-7': 'America/Denver',
    '-6': 'America/Mexico_City',
    '-5': 'America/New_York',
    '-4': 'America/Caracas',
    '-3.5': 'America/St_Johns',
    '-3': 'America/Argentina/Buenos_Aires',
    '-2': 'Atlantic/Azores',
    '-1': 'Atlantic/Azores',
    0: 'Europe/London',
    1: 'Europe/Paris',
    2: 'Europe/Helsinki',
    3: 'Europe/Moscow',
    3.5: 'Asia/Tehran',
    4: 'Asia/Baku',
    4.5: 'Asia/Kabul',
    5: 'Asia/Karachi',
    5.5: 'Asia/Calcutta',
    6: 'Asia/Colombo',
    7: 'Asia/Bangkok',
    8: 'Asia/Singapore',
    9: 'Asia/Tokyo',
    9.5: 'Australia/Darwin',
    10: 'Pacific/Guam',
    11: 'Asia/Magadan',
    12: 'Asia/Kamchatka'
  };
    // @ts-ignore
    this.currentTimezone = timezones[-offset / 60];
    const timeZone = localStorage.getItem('_timeZones');
    if (timeZone && timeZone.length > 0 ) {
      this.timeZoneList = JSON.parse(timeZone);
    } else {
      this.baseRequestService.doRequest(`/api/cyberutils/dummy/getSupportedTimeZones`, 'post', {})
        .subscribe((result: any) => {
        this.timeZoneList = result;
      });
    }
  }
  addCompany(name: any): void {
    this.addCompanyChange.next(name);
  }
  addSite(ele: any, name: any): void {
    ele.action = name.action;
    this.addSiteChange.next(ele);
  }

  async getAgents(cId?: string): Promise<any> {
    const query = (cId)
      ? { query: { bool: { must: [ {match: {'companyRef.id.keyword': cId + ''}},
              {match: {agent_type: 1}}, {exists: {field: 'agent_type'}}] } } }
      : { query: { bool: { must: [ {match: {agent_type: 4}}, {exists: {field: 'agent_type'}}],
            must_not: [{exists: {field: 'companyRef.id.keyword'}}] } } };
    const q = JSON.stringify(query); const skip = 0; const limit = 1000;
    return await this.agentService.getAllApiAgentGet({q, skip, limit}).toPromise();
  }
  async sortFn(arr: any, key?: string): Promise<any> {
    if (key) {
      return await arr.sort((a: any, b: any) => {
        const c = a[key]; const d = b[key];
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
    }
    return await arr.sort((c: any, d: any) => {
      if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
    });
  }
}
